export const KIWANIS_BLUE_COLOR: string = '#003874';

export const EMAIL_REGEX: RegExp = new RegExp(/^[\w!#$%&'*+/=?`{|}~^-]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/);

// -------------------------------------- Languages -------------------------------------- //

export const LANGUAGE_CODE = {
  en: 'en',
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  nl: 'nl',
  ja: 'ja',
  zh_CHT: 'zh-CHT'
};
export const LANGUAGE_ARRAY = [
  {code: LANGUAGE_CODE.en, value: "English"},
  {code: LANGUAGE_CODE.de, value: "Deutsch"},
  {code: LANGUAGE_CODE.es, value: "Español"},
  {code: LANGUAGE_CODE.fr, value: "Français"},
  {code: LANGUAGE_CODE.it, value: "Italiano"},
  {code: LANGUAGE_CODE.nl, value: "Nederlands"},
  {code: LANGUAGE_CODE.ja, value: "日本語"},
  {code: LANGUAGE_CODE.zh_CHT, value: "中文(繁體) 舊版"}
];

// -------------------------------------- Club type -------------------------------------- //

export const CLUB_TYPE = {
  KKids: 'KKids',
  Kiwanis: 'Kiwanis',
  KeyClub: 'KeyClub',
  Cki: 'Cki',
  BuildersClub: 'BuildersClub',
  Aktion: 'Aktion'
};


export const CLUB_TYPE_ARRAY = [
  {code: CLUB_TYPE.Cki},
  {code: CLUB_TYPE.Kiwanis},
  {code: CLUB_TYPE.KeyClub},
  {code: CLUB_TYPE.Aktion},
  {code: CLUB_TYPE.BuildersClub},
  {code: CLUB_TYPE.KKids}
];

export const CLUB_TYPE_ARRAY_FOR_MOBILE_VIEW = [
  {code: CLUB_TYPE.Kiwanis},
  {code: CLUB_TYPE.Cki},
  {code: CLUB_TYPE.KeyClub},
  {code: CLUB_TYPE.Aktion},
  {code: CLUB_TYPE.BuildersClub},
  {code: CLUB_TYPE.KKids}
];

export const SIGNATURE_SERVICE_PROJECTS = [
  {label: "Community athletics", value: "Community athletics"},
  {label: "Community service", value: "Community service"},
  {label: "Children’s health", value: "Children’s health"},
  {label: "Childhood literacy", value: "Childhood literacy"},
  {label: "Clothing/shoe drive", value: "Clothing/shoe drive"},
  {label: "Education/local school", value: "Education/local school"},
  {label: "Food supplies", value: "Food supplies"},
  {label: "Fundraising", value: "Fundraising"},
  {label: "Holidays", value: "Holidays"},
  {label: "Scholarships", value: "Scholarships"},
  {label: "Scouting", value: "Scouting"},
  {label: "Other", value: "Other"},
];

// --------------------------------------- Country ------------------------------------- //

export const COUNTRY = {
  US: 'US',
  CA: 'CA',
  AU: 'AU'
};

// --------------------------------------  Enums  --------------------------------------- //

export enum PreferredContactMethodInfo {
  Email,
  Phone,
  Any
}

export enum ClubTypeInfo {
  Kiwanis,
  Cki,
  Aktion,
  KeyClub,
  KKids,
  BuildersClub
}

export enum SearchDistanceCategory {
  Radius10,
  Radius20,
  Radius30,
  Radius40,
  Radius50,
  Radius100
}

export enum DistanceUnitsInfo {
  Km,
  Miles
}

export enum MeetingDayInfo {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export enum NumberOfMembersCategoryInfo {
  From0To20,
  From21To30,
  From31To50,
  From51To100,
  From101To200,
  From201To300,
  From301ToMore
}
